<template>
    <v-container class="px-0">
        <div class="jumanji-historical">
            <v-card
                class="cards-jumanji mx-auto"
                tile
            >
                <v-list
                    dense
                >
                    <v-subheader>Últimas tarjetas usadas por tipo</v-subheader>

                    <v-list-item
                        v-for="(item, index) in historical.lastCardsUsedByTypes"
                        :key="index"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                <span
                                    class="pr-2"
                                    v-text="item.card_type_name"
                                />
                                <span
                                    class="text-color-primary"
                                    v-text="`${item.card_id} ${item.question}`"
                                />
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <div
                        v-if="!historical.lastCardsUsedByTypes || !historical.lastCardsUsedByTypes.length"
                        class="text-small-grey"
                    >
                        No se han lanzado tarjetas aún en este juego
                    </div>
                </v-list>
            </v-card>

            <v-card
                class="cards-jumanji mx-auto"
                tile
            >
                <v-list
                    dense
                >
                    <v-subheader>Última pregunta lanzada</v-subheader>

                    <v-list-item
                        v-for="(item, index) in getLastCardData"
                        :key="index"
                    >
                        <v-list-item-content>
                            <span class="text-small text-gray-800">
                                {{ item.name }} <span
                                    class="text-color-primary"
                                    v-html="item.value"
                                />
                            </span>
                        </v-list-item-content>
                    </v-list-item>

                    <div
                        v-if="!getLastCardData.length"
                        class="text-small-grey"
                    >
                        No se han lanzado tarjetas aún en este juego
                    </div>
                </v-list>
            </v-card>
        </div>
    </v-container>
</template>

<script>

export default {
    name: 'JumanjiManagerHistoricalInfo',
    components: { },
    props: {
        historical: {
            type: Object,
            required: true
        } // lastCardsUsedByTypes: [], lastCard: {}
    },
    data() {
        return {

        }
    },
    computed: {
        getLastCardData() {
            const result = [];

            const card = this.historical?.lastCard;
            if (card?.card_id) {
                let value = 'Todo el Juego'
                if (card.team) {
                    if (!card.user_id) {
                        value = `Equipo ${card.team} (Completo)`;
                    } else {
                        const user = card?.user?.name ?? card.user_id;
                        value = `Equipo ${card.team} Jugador ${user}`;
                    }
                }
                result.push({ name: 'Carta lanzada a', value });

                value = `${card?.card_type?.name ?? ''} > ${card?.card?.question ?? card.card_id}`;
                result.push({ name: 'Pregunta lanzada', value });

                const html = `<a href="${this.getImagePath(card.card.image)}" target="_blank">${card.card.image.replace('games/jumanji/cards/', '')}</a>`
                result.push({ name: 'Imagen cargada', value: html });

                // Archivos de imagen que se ha cargado: https://games.digitabilities.com/images/tarjeta_imagino_05.png
            }

            return result;
        }

    },

    async created() {

    },
    methods: {
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=' + image : '';
        }
    }
}
</script>

<style scoped>
.jumanji-historical{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 20px;
  row-gap: 20px;

}

.jumanji-historical .v-list-item__content {
  padding: 4px 0 !important;
}

.jumanji-historical .v-list-item--dense, .jumanji-historical .v-list--dense .v-list-item {
  min-height: 20px !important;
}

.cards-jumanji{
  max-width: 100%;
  min-width: 250px;
}

.with-header-all .v-list-item-group > *:first-child > *:first-child{
  border-bottom: 1px solid #00b5e2 !important;
}

</style>

<style>

</style>
