import { render, staticRenderFns } from "./jumanjiManagerHistoricalInfo.vue?vue&type=template&id=bb7a0fc6&scoped=true&"
import script from "./jumanjiManagerHistoricalInfo.vue?vue&type=script&lang=js&"
export * from "./jumanjiManagerHistoricalInfo.vue?vue&type=script&lang=js&"
import style0 from "./jumanjiManagerHistoricalInfo.vue?vue&type=style&index=0&id=bb7a0fc6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb7a0fc6",
  null
  
)

export default component.exports